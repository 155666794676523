.footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 100px 50px;
    background-color: #eaeaeb;
    box-shadow: 0 -11px 10px rgba(0, 0, 0, 0.2);
    color: #515355;
}

.footer-left, .footer-center, .footer-right {
    flex: 1;/*same width*/
}

/************************************************/
/*********************LEFT***********************/
/************************************************/
.footer-left-logo {
    height: 125px;
    cursor: pointer;
}

/************************************************/
/*********************CENTER*********************/
/************************************************/
.footer-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.footer-center svg {
    font-size: 48px;
    cursor: pointer;
}

/************************************************/
/*********************RIGHT**********************/
/************************************************/
.footer-right {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.footer-right-block {
    width: fit-content;
}

.footer-right-info-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: fit-content;
    padding-bottom: 4px;
}

.footer-right-info-row span, .footer-right-info-row a {
    padding-left: 5px;
    text-decoration: none !important;
}

/************************************************/
/*********************MENTIONS*******************/
/************************************************/
.footer-mentions {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
}

.footer-mentions a {
    padding: 0 10px;
}

/**************************************************************************************************************************************************/
/********************************************************************MEDIA QUERIES*****************************************************************/
/**************************************************************************************************************************************************/
@media screen and (max-width: 720px) {
    .footer {
        flex-direction: column;
        padding: 50px 20px;
    }

    .footer-left, .footer-center, .footer-right {
        padding-bottom: 30px;
    }

    .footer-right-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}