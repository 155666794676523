.register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.register-title {
    position: relative;
    padding-top: 20px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 56px;
    font-weight: bold;
    color: #515355;
}

.register-title-circle {
    z-index: -1;
    position: absolute;
    top: 35px;
    left: 50%;
    width: 40px;
    height: 40px;
    background-color: #f3ba1a;
    border-radius: 50%;
}

.register-registration {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
}

.register-registration-image {
    width: 40%;
}

.register-registration-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #5153552b;
    border-radius: 20px;
}

.register-registration-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
}

.register-registration-form-row .MuiFormControl-root {
    padding-bottom: 32px;
    padding-right: 25px;
    margin: 0;
    width: 40%;
}

.send-registration-form-button-container {
    display: flex;
    justify-content: center;
}

.send-registration-form-button {
    width: 160px !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

/**************************************************************************************************************************************************/
/********************************************************************MEDIA QUERIES*****************************************************************/
/**************************************************************************************************************************************************/
@media screen and (max-width: 1024px) {
    .register-registration {
        flex-direction: column;
    }

    .register-registration-image {
        width: 100%;
        padding-bottom: 50px;
    }

    .register-registration-form {
        width: 100%;
    }

    .register-registration-form-row .MuiFormControl-root {
        width: 100%;
    }

    .register-registration-form {
        padding: 0;
        border: none;
        border-radius: 0;
    }
}
