.introduction {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 32px 100px;
}

.introduction-right img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
}

.introduction-right {
    flex: 1;
    display: flex;
    justify-content: center;
}

.introduction-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 20px;
}

.introduction-left-title {
    color: #9EA2A6;
    font-size: 50px;
    font-weight: 600;
}

.introduction-left-sub-title {
    color: #515355;
    font-size: 42px;
    font-weight: 600;
    padding-bottom: 50px;
}

.introduction-left-description {
    color: #515355;
    font-size: 18px;
    text-align: justify;
    padding-bottom: 50px;
}

.introduction-left-actions {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.introduction-left-reviews-description {
    font-size: 14px;
    color: #6B6B6B;
}

.introduction-left-reviews {
    display: flex;
}

/**************************************************************************************************************************************************/
/********************************************************************MEDIA QUERIES*****************************************************************/
/**************************************************************************************************************************************************/
@media screen and (max-width: 720px) {
    .introduction {
        flex-direction: column;
        padding: 32px 10px;
    }

    .introduction-left {
        padding-right: 0;
    }

    .introduction-left-actions {
        justify-content: center;
        margin-bottom: 32px;
    }

    .introduction-left-title {
        font-size: 42px;
    }

    .introduction-left-sub-title {
        font-size: 30px;
    }

    .introduction-left-reviews {
        justify-content: center;
        margin-bottom: 32px;
    }
}
