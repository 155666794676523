.prices {
    overflow: hidden;
    width: 100%;
    position: relative;
    margin-bottom: 40px;
}

.prices-title {
    position: relative;
    padding-top: 8px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 56px;
    font-weight: bold;
    color: #515355;
}

.prices-title-circle {
    z-index: -1;
    position: absolute;
    top: 23px;
    left: 50%;
    width: 40px;
    height: 40px;
    background-color: #f3ba1a;
    border-radius: 50%;
}

.prices-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.prices-cards-card {
    border-radius: 20px;
    background-color: #F1F1F1;
    padding: 0 8px 8px 8px;
    margin-bottom: 24px;
}

.prices-cards-card-title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    color: white;
}

.prices-cards-card-price-original {
    padding-top: 16px;
    color: red;
    text-align: center;
    margin-bottom: 0;
    font-size: 14px;
}

.prices-cards-card-price-discount {
    font-size: 40px;
    font-weight: bold;
    color: #515355;
    text-align: center;
    margin: 0;
}

.prices-cards-card-price-per-day {
    font-size: 14px;
    text-align: center;
    margin-top: 0;
    color: #8B8989;
}

.prices-cards-card-price-separator {
    border-bottom: 1px solid #bbbbbb;
    width: 90%;
    margin: 0 auto;
}

.prices-cards-card-services-service-ok, .prices-cards-card-services-service-ko  {
    display: flex;
    align-items: center;
}

.prices-cards-card-services-service-ko {
    color: #5153555e;
}

/**************************************************************************************************************************************************/
/********************************************************************MEDIA QUERIES*****************************************************************/
/**************************************************************************************************************************************************/
@media screen and (max-width: 720px) {
    .prices-cards {
        flex-direction: column;
    }

    .prices-cards {
        width: 90%;
        margin: 0 auto;
    }
}
