.shared-office {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
}

.shared-office-section-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.shared-office-section-1-left {
    width: 70%;
    object-fit: contain;
    position: relative;
}

.shared-office-section-1-left-image {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.shared-office-section-1-left-image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #F1F1F1;
    font-size: 56px;
    font-weight: bold;
}

.shared-office-section-1-right {
    width: 25%;
}

.shared-office-section-1-right-features {
    background-color: #F1F1F1;
    border-radius: 20px;
    padding: 10px 5px 10px 50px;
}

.shared-office-section-1-right-features-title {
    color: #515355;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 4px solid #f3ba1a;
}

.shared-office-section-1-right-features-feature, .shared-office-section-1-right-features-attention {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #515355;
}

.shared-office-section-1-right-features-attention {
    padding-top: 10px;
    font-size: 10px;
}

.shared-office-section-1-right-features-feature svg {
    padding-right: 5px;
}

/**************************************************************************************************************************************************/
/********************************************************************MEDIA QUERIES*****************************************************************/
/**************************************************************************************************************************************************/
@media screen and (max-width: 1325px) {
    .shared-office-section-1 {
        flex-direction: column;
    }

    .shared-office-section-1-left {
        width: 90%;
    }

    .shared-office-section-1-right {
        width: 90%;
        margin-top: 25px;
    }

    .shared-office-section-1-left-image-text {
        font-size: 42px;
    }
}