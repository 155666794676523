.advantages {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.advantages-title {
    position: relative;
    padding-top: 50px;
    text-align: center;
    font-size: 56px;
    font-weight: bold;
    color: #515355;
}

.advantages-title-circle {
    z-index: -1;
    position: absolute;
    top: 65px;
    left: 50%;
    width: 40px;
    height: 40px;
    background-color: #f3ba1a;
    border-radius: 50%;
}

.advantages-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 100px 20px 100px;
}

.advantages-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 50px;
}

.advantages-row-advantage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    flex: 1;
}

.advantages-row-advantage-text {
    font-size: 24px;
    font-weight: bold;
    color: #515355;
    margin-top: 0;
}

/**************************************************************************************************************************************************/
/********************************************************************MEDIA QUERIES*****************************************************************/
/**************************************************************************************************************************************************/
@media screen and (max-width: 720px) {
    .advantages-row {
        flex-direction: column;
    }

    .advantages-icons {
        padding: 20px 10px 20px 10px;
    }
}