html, body, #root {
    overflow: visible;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: visible;
}

.content {
    flex: 1; /*make the flex item expand and occupy the available space within the container*/
    overflow-y: auto; /*Enable scrolling for content*/
}