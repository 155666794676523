.services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.services-title {
    position: relative;
    padding-bottom: 30px;
    text-align: center;
    font-size: 56px;
    font-weight: bold;
    color: #515355;
}

.services-title-circle {
    z-index: -1;
    position: absolute;
    top: 15px;
    left: 50%;
    width: 40px;
    height: 40px;
    background-color: #f3ba1a;
    border-radius: 50%;
}